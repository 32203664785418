import React, { useState, useEffect } from 'react';
import "./MessageModalBody.css";

const messageMap = {
    //add messages here
};

const MessageModalBody = ({ hideModal }) => {
    const [infoMessage, setInfoMessage] = useState('');
    const [infoMessageClass, setInfoMessageClass] = useState('');

    useEffect(() => {
        const hash = window.location.hash;
        const queryString = hash.substring(hash.indexOf('?') + 1);
        const urlParams = new URLSearchParams(queryString);
        const messageCode = urlParams.get('code');
        const messageData = messageMap[messageCode];
        if (!messageData) {
            return;
        }
        const message = messageData['msg'];
        setInfoMessage(message);
        setInfoMessageClass(messageData['class']);
    }, []);

    const handleCancel = () => {
        hideModal();
    };

    return (
        <div className='mt-2 p-0 p-md-3'>
            <div style={{ width: '100%', textAlign: 'center' }}>
                {(infoMessage) && (<div id="infoModalMessage" style={{ fontSize: '120%' }} className={`mt-3 alert alert-${infoMessageClass}`}>{infoMessage}</div>)}
            </div>
            <div className="d-flex justify-content-end">
                <button className="btn btn-primary m-2" onClick={handleCancel}>Închide</button>
            </div>
        </div>
    );
};

export default MessageModalBody;
