const ReasonsToJoin = () => {
    return (<section className="bg-1">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12" style={{ padding: "20px", paddingTop: "50px" }}>

                    <div className='row advantage-box box-left'>
                        <div className='col-12 col-md-2 d-flex justify-content-md-end justify-content-start'>
                            <img src="assets/be_ready_for_future.jpg" alt="Imagine descriere" className="icon-image" />
                        </div>
                        <div className='col-12 col-md-10 label_text' style={{ textAlign: "left" }}>
                            <div className="label_header">Pregătește-te pentru viitor.</div>
                            <div>
                                Învață să utilizezi inteligența artificială. Fii pregătit pentru ceea ce
                                urmează.
                            </div>
                        </div>
                    </div>

                    <div className='row advantage-box box-right'>
                        <div className='col-12 col-md-2 d-flex justify-content-md-end justify-content-start'>
                            <img src="assets/work_more_efficient.jpg" alt="Imagine descriere" className="icon-image" />
                        </div>
                        <div className='col-12 col-md-10 label_text' style={{ textAlign: "left" }}>
                            <div className="label_header">Simplifică-ți munca.</div>
                            <div>
                                Învață cum inteligența artificială te poate ajuta să lucrezi mai eficient.
                            </div>
                        </div>
                    </div>

                    <div className='row advantage-box box-left'>
                        <div className='col-12 col-md-2 d-flex justify-content-md-end justify-content-start'>
                            <img src="assets/stay_competitive.jpg" alt="Imagine descriere" className="icon-image" />
                        </div>
                        <div className='col-12 col-md-10 label_text' style={{ textAlign: "left" }}>
                            <div className="label_header">Îmbunătățește-ți abilitățile. Rămâi competitiv.</div>
                            <div>
                                Dezvoltă-ți expertiza profesională prin integrarea cunoștințelor de inteligență
                                artificială.
                            </div>
                        </div>
                    </div>


                    <div className='row advantage-box box-right'>
                        <div className='col-12 col-md-2 d-flex justify-content-md-end justify-content-start'>
                            <img src="assets/fear.jpg" alt="Imagine descriere" className="icon-image" />
                        </div>
                        <div className='col-12 col-md-10 label_text' style={{ textAlign: "left" }}>
                            <div className="label_header">Depăsește-ți frica de tehnologie.</div>
                            <div>
                                Înțelege bazele inteligenței artificiale, eliminând teama și confuzia legate de
                                tehnologie.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default ReasonsToJoin;