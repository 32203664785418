import React from "react";
import Header from "./Header.js"
import Footer from "./Footer.js"


const TermsBody = () => {
    return (
        <>
            <div className="container">
                <h1>Termeni şi condiții</h1>
                <br />

                <h3>Introducere</h3>
                <br />

                <p>
                    Prin accesarea și utilizarea website-ului curs.viorelspinu.com, vă angajați să respectați următorii termeni
                    și condiții de utilizare, toate legile și reglementările aplicabile și sunteți de acord că sunteți
                    responsabil pentru conformitatea cu orice legi locale aplicabile. Dacă nu sunteți de acord cu vreunul dintre
                    acești termeni, vǎ rugǎm sǎ nu utilizați sau accesați acest site.
                </p>

                <h3>Distribuirea Materialelor este permisǎ</h3>
                <h5>(CreativeCommons NonDerivative NonCommercial)</h5>

                <p>
                    Materialele oferite pe website-ul curs.viorelspinu.com sunt disponibile sub termenii Licenței Internaționale
                    Creative Commons "Atribuire-Necomercial-FărăModificări 4.0 Internațional" <a
                        href="https://creativecommons.org/licenses/by-nc-nd/4.0/deed.ro" target="_blank" rel="noopener noreferrer">
                        (CC BY-NC-ND 4.0 DEED)
                    </a>.
                </p>

                <pre>
                    * Distribuire permisa: Poți să poți sǎ distribui cursurile în orice mediu sau format, cu conditia de a acorda credit
                    prin link catre https://curs.viorelspinu.com.

                    * Necomercial — Nu poți folosi această operă în scopuri comerciale.

                    * Fara modificari — Nu este permis sa aduci modificari cursurilor înainte de a distribui.

                    * Fără restricții suplimentare — Nu poți impune termeni juridici sau măsuri tehnologice care restricționează din punct de vedere legal
                    acțiunile altor utilizatori care sunt permise de către licență.
                </pre>

                <h3>Declinarea Responsabilității și Limitarea Răspunderii</h3>

                <p>
                    Materialele de pe website-ul curs.viorelspinu.com sunt furnizate "așa cum sunt", fără garanții de niciun
                    fel, exprimate sau implicite. În limitele maxime permise de lege, curs.viorelspinu.com exclude toate
                    garanțiile, exprimate sau implicite, inclusiv, dar fără a se limita la, garanțiile implicite de
                    comercializare și adecvare pentru un anumit scop. curs.viorelspinu.com nu garantează exactitatea,
                    probabilitatea rezultatelor sau fiabilitatea utilizării materialelor pe website-ul său sau altfel legate de
                    astfel de materiale.
                </p>

                <p>
                    În niciun caz curs.viorelspinu.com sau furnizorii săi nu vor fi răspunzători pentru orice daune (inclusiv,
                    fără limitare, daune pentru pierderea de profit, întreruperea afacerii, pierderea informațiilor) care
                    rezultă din utilizarea sau incapacitatea de a utiliza materialele disponibile.
                </p>

                <h3>Link-uri si Servicii Externe</h3>

                <p>
                    curs.viorelspinu.com nu a revizuit toate site-urile mentionate in cursurile furnizate și nu este responsabil
                    pentru conținutul oricărui astfel de site. Includerea oricărui link nu implică aprobarea de către
                    curs.viorelspinu.com a site-ului. Utilizarea oricărui astfel de site legat este pe propriul risc al
                    utilizatorului.
                </p>

                <h3>Modificări ale Termenilor de Utilizare</h3>

                <p>
                    curs.viorelspinu.com își rezervă dreptul de a revizui acești termeni și condiții la orice moment. Prin
                    utilizarea acestui website după orice astfel de revizuire, vă angajați să respectați termenii revizuiți.
                </p>

                <h3>Utilizare Interzisă pentru Activitati Ilegale sau Imorale</h3>
                <p>
                    Este strict interzisă utilizarea informatiilor oferite de curs.viorelspinu.com pentru orice fel de
                    activitate ilegala, imorala, incluzand dar fara a se limita la:
                </p>

                <ul>
                    <li>Distribuirea de Malware: Transmiterea de viruși, troieni, worms, bombe logice sau orice alt material malefic
                        care este menit să afecteze operațiunile normale ale unui computer, ale altor dispozitive sau ale
                        website-ului.
                    </li>
                    <li>Phishing și Ingineria Socială: Încercările de a obține acces neautorizat la informații sensibile ale
                        utilizatorilor, cum ar fi numele, adresele, detaliile conturilor bancare sau orice alte date personale, prin
                        înșelăciune sau alte metode frauduloase.
                    </li>
                    <li>Atacuri cibernetice: Lansarea oricăror atacuri cibernetice, cum ar fi atacuri de tipul DoS (Denial of
                        Service) sau DDoS (Distributed Denial of Service), care sunt menite să perturbe accesul la [Numele
                        Website-ului] sau la serviciile sale.
                    </li>
                    <li>Utilizarea în Scopuri Malefice: Orice utilizare a informațiilor, datelor sau a materialelor obținute de pe
                        curs.viorelspinu.com în scopuri care sunt ilegale, dăunătoare, frauduloase, defăimătoare, discriminatorii
                        sau în orice alt mod malefic. Acest lucru include, dar nu se limitează la, utilizarea informațiilor pentru a
                        hărțui, abuza, defăima, denigra, amenința sau viola drepturile de intimitate și publicitate ale altor
                        persoane.
                    </li>
                </ul>
                <p>
                    <b>Consecințe:</b> Încălcarea acestei politici de utilizare interzisă va duce la acțiuni imediate de către [Numele
                    Website-ului], care pot include suspendarea sau terminarea accesului utilizatorului la servicii, precum și
                    inițierea de acțiuni legale împotriva celor vinovați de astfel de abuzuri, dacă este necesar.
                    curs.viorelspinu.com își rezervă, de asemenea, dreptul de a colabora cu autoritățile legale în investigarea
                    oricăror activități ilegale sau dăunătoare.
                </p>
                <p>
                    <b>Răspundere:</b> Utilizatorii sunt singurii responsabili pentru orice daune sau pierderi cauzate direct sau
                    indirect prin orice utilizare abuzivă a serviciului oferit de curs.viorelspinu.com. curs.viorelspinu.com
                    nu va fi răspunzător pentru orice acte comise de utilizatori care încalcă aceste termene.
                </p>

                <h3>Inexactități în Conținut</h3>
                <p>
                    curs.viorelspinu.com depune eforturi considerabile pentru a asigura acuratețea, fiabilitatea și actualitatea
                    conținutului disponibil pe site. Cu toate acestea, nu putem garanta că toate informațiile furnizate sunt
                    complet lipsite de erori, inexactități sau omisiuni. Conținutul poate include inadvertențe tehnice, erori
                    tipografice sau pot fi învechite la momentul accesării de către utilizatori.
                </p>
                <p>
                    Prin urmare, curs.viorelspinu.com nu își asumă responsabilitatea pentru orice inexactitate, eroare sau
                    omisiune în conținutul furnizat, nici pentru orice decizie luată sau acțiune întreprinsă de utilizatori pe
                    baza informațiilor sau datelor disponibile pe site. Utilizatorii sunt încurajați să verifice independent
                    exactitatea, completitudinea și actualitatea oricărei informații furnizate pe site înainte de a se baza pe
                    aceasta în orice scop.
                </p>
                <p>
                    curs.viorelspinu.com își rezervă dreptul de a corecta orice erori, inexactități sau omisiuni și de a
                    modifica sau actualiza informațiile de pe site la orice moment, fără notificare prealabilă. Cu toate
                    acestea, curs.viorelspinu.com nu se angajează să actualizeze materialele, cu excepția cazului în care acest
                    lucru este necesar conform legii sau reglementărilor aplicabile.
                </p>
                <p>
                    Este responsabilitatea utilizatorului să evalueze acuratețea, completitudinea sau utilitatea oricărei
                    informații, opinii, sfaturi sau alt conținut disponibil prin intermediul site-ului curs.viorelspinu.com. Vă
                    rugăm să solicitați sfatul profesioniștilor, după caz, în legătură cu evaluarea oricăror informații
                    specifice, opinii, sfaturi sau alt conținut.
                </p>

                <h3>Reclamații legate de Produse sau Servicii Furnizate de Terți</h3>

                <p>
                    curs.viorelspinu.com poate facilita accesul la produse și servicii oferite de terți. Deși ne străduim să
                    selectăm colaboratori de încredere și să oferim acces la informații și resurse de calitate,
                    "curs.viorelspinu.com" nu controlează și nu garantează calitatea, siguranța, legalitatea sau
                    disponibilitatea produselor sau serviciilor furnizate de terți, precum și acuratețea sau completitudinea
                    descrierilor, recenziilor sau recomandărilor legate de acestea.
                </p>
                <p>
                    Utilizatorii sunt responsabili pentru verificarea informațiilor și pentru orice decizii de cumpărare bazate
                    pe acestea. Orice tranzacție sau interacțiune între utilizatori și furnizorii terți de produse sau servicii
                    se realizează exclusiv între utilizator și respectivul terț. "curs.viorelspinu.com" nu face parte din, și nu
                    este responsabil pentru, niciun aspect al acestor tranzacții sau interacțiuni.
                </p>
                <p>
                    În cazul în care apar dispute sau reclamații legate de produsele sau serviciile oferite de terți,
                    utilizatorii trebuie să se adreseze direct furnizorului sau terțului în cauză pentru soluționarea acestora.
                    "curs.viorelspinu.com" nu va fi considerat mediator, arbitru sau în orice alt mod responsabil pentru
                    soluționarea acestor dispute.
                </p>
                <p>
                    Utilizatorii sunt de acord să exonereze "curs.viorelspinu.com", de orice reclamații, cereri, daune,
                    pierderi, costuri, cheltuieli sau alte responsabilități, cunoscute sau necunoscute, care decurg din sau în
                    orice mod legate de astfel de dispute cu terți.
                </p>

                <h3>Soluționarea Disputelor</h3>
                <p>
                    Pentru a asigura o rezolvare eficientă și echitabilă a oricăror dispute care pot apărea în legătură cu
                    utilizarea website-ului curs.viorelspinu.com, accesul la serviciile sale sau interpretarea Termenilor și
                    Condițiilor, am stabilit următoarea procedură obligatorie de soluționare a disputelor:
                </p>
                <p>
                    <b>Notificarea Disputei:</b> În cazul în care apare o dispută, partea reclamantă trebuie să trimită celeilalte
                    părți o notificare scrisă, detaliind natura disputei și rezultatul dorit. Partea reclamată va avea la
                    dispoziție 30 zile de la primirea notificării pentru a răspunde și a încerca soluționarea amiabilă a problemei.
                </p>
                <p>
                    <b>Medierea:</b> Dacă disputele nu pot fi rezolvate prin negocieri directe în termenul specificat, părțile sunt de
                    acord să se angajeze în mediere înainte de a lua în considerare litigiul în instanță. Medierea va fi
                    efectuată de un mediator neutru și calificat, ale cărui costuri vor fi împărțite în mod egal între părți, cu
                    excepția cazului în care părțile convin altfel.
                </p>
                <p>
                    <b>Acordul de Mediere:</b> Orice acord rezultat din mediere va fi în scris și semnat de ambele părți, având forța
                    unui contract obligatoriu între părțile implicate.
                </p>
                <p>
                    <b>Confidențialitatea:</b> Procesul de mediere va fi strict confidențial și niciuna dintre părți nu va divulga
                    informații legate de discuții, negocieri sau acorduri fără consimțământul scris al celeilalte părți.
                </p>

                <h3>Datele Personale și Confidențialitatea</h3>

                <p>
                    La curs.viorelspinu.com, respectăm intimitatea dvs. și ne angajăm să protejăm datele personale ale
                    vizitatorilor și utilizatorilor site-ului nostru. Această secțiune explică politicile și practicile noastre
                    privind colectarea, utilizarea și protecția datelor personale, precum și drepturile pe care le aveți în
                    legătură cu informațiile dvs. personale.
                </p>
                <p>
                    <b>Colectarea Datelor:</b> Colectăm date personale atunci când vă înregistrați pentru a primi cursurile noastre,
                    când ne contactați prin formularul de pe site sau când utilizați serviciile noastre. Datele colectate pot include,
                    dar nu este obligatoriu a se limita la numele dvs., adresa de e-mail și orice alte informații pe care alegeți să ni le
                    furnizați.
                </p>
                <p>
                    <b>Utilizarea Datelor:</b> Utilizăm datele personale colectate pentru a vă oferi cursurile solicitate, pentru a îmbunătăți serviciile
                    noastre și pentru a comunica cu dvs. în legătură cu informații relevante despre cursurile oferite. Nu vom
                    vinde, închiria sau distribui datele dvs. personale către terți în scopuri comerciale fără consimțământul
                    dvs. explicit.
                </p>
                <p>
                    <b>Protecția Datelor:</b> Implementăm măsuri tehnice și organizatorice adecvate pentru a proteja datele personale împotriva pierderii,
                    utilizării greșite, accesului neautorizat, divulgării, modificării sau distrugerii. Cu toate acestea, nicio
                    metodă de transmisie pe internet sau metodă de stocare electronică nu este 100% sigură. Prin urmare, în timp
                    ce ne străduim să protejăm datele dvs. personale, nu putem garanta securitatea absolută a acestora.
                </p>
                <p>
                    <b>Drepturile Dvs.:</b> Aveți dreptul de a accesa, corecta, șterge sau restricționa prelucrarea datelor dvs. personale colectate de
                    curs.viorelspinu.com. De asemenea, aveți dreptul de a vă opune prelucrării datelor dvs. personale și dreptul
                    de portabilitate a datelor. Pentru a exercita aceste drepturi, vă rugăm să ne contactați folosind detaliile
                    de contact furnizate pe site.
                </p>
                <p>
                    <b>Modificări ale Politicii de Confidențialitate:</b> Ne rezervăm dreptul de a modifica această politică de confidențialitate la orice moment. Orice modificări
                    vor fi postate pe această pagină și, dacă schimbările sunt semnificative, vă vom notifica prin e-mail sau
                    prin intermediul site-ului.
                </p>
                <p>
                    Pentru întrebări sau preocupări legate de practicile noastre de confidențialitate, vă rugăm să ne contactați
                    folosind detaliile de contact furnizate pe site.
                </p>

                <h3>Modificarea serviciilor</h3>

                <p>
                    curs.viorelspinu.com își rezervă dreptul de a modifica, suspenda sau înceta, temporar sau permanent, orice
                    aspect al serviciilor oferite, inclusiv disponibilitatea oricărei funcționalități a site-ului, baze de date
                    sau conținut, la discreția sa și fără notificare prealabilă sau răspundere. Aceasta include dreptul de a
                    introduce sau a elimina funcționalități, de a schimba interfața, de a limita accesul la anumite secțiuni ale
                    site-ului sau de a impune limite pe utilizare și stocare, în scopul de a îmbunătăți site-ul sau de a se
                    conforma cu legislația aplicabilă.
                </p>
                <p>
                    De asemenea, curs.viorelspinu.com poate introduce noi taxe pentru anumite servicii sau poate modifica taxele
                    existente, după caz, cu notificare prealabilă utilizatorilor, în conformitate cu legislația aplicabilă.
                </p>
                <p>
                    Utilizatorii recunosc și acceptă că modificările aduse serviciilor pot afecta modul în care aceștia
                    accesează, utilizează sau interacționează cu site-ul și materialele oferite. Utilizatorii sunt încurajați să
                    verifice periodic Termenii și Condițiile pentru a fi la curent cu orice modificări.
                </p>
                <p>
                    Continuarea utilizării serviciilor oferite de curs.viorelspinu.com după efectuarea oricăror modificări va
                    constitui acceptul utilizatorilor față de aceste schimbări. Dacă orice modificare este inacceptabilă pentru
                    utilizator, acesta are dreptul de a înceta utilizarea serviciilor site-ului.
                </p>

                <h3>Accesul minorilor</h3>

                <p>
                    curs.viorelspinu.com recunoaște importanța protecției minorilor în mediul online și se angajează să respecte
                    toate legile aplicabile privind protecția minorilor. Site-ul nostru este destinat utilizării de către
                    persoanele care au atins vârsta majoratului conform legislației locale sau naționale aplicabile (de obicei,
                    18 ani, dar poate varia în funcție de jurisdicție).
                </p>
                <p>
                    Nu colectăm în mod intenționat date personale de la persoane sub vârsta de majorat. În cazul în care aflăm
                    că am colectat orice informație personală de la un minor fără verificarea consimțământului părinților, vom
                    șterge acele informații cât mai curând posibil. Dacă părinții sau tutorii legali cred că minorii lor ne-au
                    furnizat date personale, îi încurajăm să ne contacteze și vom lua măsurile necesare pentru a elimina aceste
                    informații.
                </p>
                <p>
                    Părinții și tutorii sunt încurajați să supravegheze activitatea online a minorilor lor și să participe
                    împreună cu ei la activitățile online, pentru a asigura o experiență sigură și educativă. Recomandăm
                    părinților să învețe minorii despre utilizarea sigură și responsabilă a informațiilor lor personale în timp
                    ce navighează pe internet.
                </p>

                <h3>Legea aplicabilă și jurisdicția</h3>

                <p>
                    Acești Termeni și Condiții, precum și orice dispută, controversă, procedură sau pretenție de orice fel care
                    decurge din sau în legătură cu acești Termeni și Condiții, inclusiv dar fără a se limita la, cele
                    non-contractuale, vor fi guvernate de și interpretate în conformitate cu legislația României.
                </p>
                <p>
                    Partea utilizatoare și curs.viorelspinu.com sunt de acord că orice dispută, controversă sau pretenție care
                    decurge din sau în legătură cu acești Termeni și Condiții, inclusiv orice chestiune privind existența,
                    validitatea sau încetarea acestora, va fi supusă în primă instanță medierii, conform secțiunii "Soluționarea
                    Disputelor" din acești Termeni și Condiții.
                </p>
                <p>
                    Dacă soluționarea pe calea medierii nu este posibilă, părțile sunt de acord să se supună jurisdicției
                    exclusive a instanțelor din București, România. Aceasta înseamnă că
                    orice proces legat de acești Termeni și Condiții va fi desfășurat în țara/jurisdicția specificată, și
                    părțile renunță la orice obiecție privind procedurile în acea jurisdicție pe baza forumului non conveniens
                    sau pe orice altă bază.
                </p>

                <h3>Politica de Cookie-uri</h3>

                <p>
                    La curs.viorelspinu.com, folosim cookie-uri și tehnologii similare pentru a îmbunătăți experiența dvs. de
                    navigare, a înțelege cum utilizați site-ul nostru și pentru a oferi funcționalități adiționale. Această
                    secțiune explică ce sunt cookie-urile, cum le folosim și cum puteți gestiona preferințele dvs. legate de
                    acestea.
                </p>
                <p>
                    <b>Ce sunt cookie-urile?</b> Cookie-urile sunt mici fișiere de text stocate pe dispozitivul dvs. de către website-uri în timpul vizitelor
                    dvs. Acestea sunt utilizate pe scară largă pentru a face website-urile să funcționeze sau să funcționeze mai
                    eficient, precum și pentru a oferi informații proprietarilor site-ului.
                </p>
                <p>
                    <b>Cum folosim cookie-urile?</b> Utilizăm cookie-uri pentru a îmbunătăți funcționalitatea site-ului, a analiza performanța site-ului și a
                    asigura securitatea. Cookie-urile ne permit, de asemenea, să personalizăm conținutul și anunțurile, să
                    oferim funcții de rețele sociale și să analizăm traficul nostru. Putem împărtăși, de asemenea, informații
                    despre utilizarea site-ului nostru cu partenerii noștri de analiză, publicitate și rețele sociale.
                </p>
                <p>
                    <b>Tipuri de cookie-uri utilizate:</b>
                </p>
                <ul>
                    <li>Cookie-uri esențiale: Aceste cookie-uri sunt necesare pentru funcționarea corectă a site-ului și nu pot fi
                        dezactivate în sistemele noastre.
                    </li>
                    <li>Cookie-uri de performanță și analiză: Aceste cookie-uri ne permit să recunoaștem și să numărăm vizitatorii
                        și să vedem cum se deplasează utilizatorii pe site-ul nostru.
                    </li>
                    <li>Cookie-uri funcționale: Acestea sunt folosite pentru a vă recunoaște atunci când reveniți pe site-ul nostru
                        și ne permit să vă oferim funcționalități îmbunătățite și personalizare.
                    </li>
                </ul>
                <p>
                    <b>Gestionarea cookie-urilor:</b> Aveți controlul asupra cookie-urilor și le puteți gestiona sau șterge după cum doriți - pentru detalii,
                    consultați aboutcookies.org. Puteți șterge toate cookie-urile care sunt deja pe computerul dvs. și puteți
                    seta majoritatea browserelor pentru a le preveni să fie plasate. Dacă faceți acest lucru, este posibil să
                    trebuiască să ajustați manual unele preferințe de fiecare dată când vizitați un site, iar unele servicii și
                    funcționalități s-ar putea să nu funcționeze.
                </p>
                <p>
                    Prin utilizarea website-ului curs.viorelspinu.com, sunteți de acord cu utilizarea cookie-urilor și a
                    tehnologiilor similare în conformitate cu această Politică de Cookie-uri.
                </p>

                <h3>Contact și comunicare</h3>

                <p>
                    La curs.viorelspinu.com, ne angajăm să oferim o experiență de calitate utilizatorilor noștri și suntem
                    deschiși la întrebările, sugestiile sau preocupările pe care le aveți cu privire la serviciile noastre. Dacă
                    aveți întrebări legate de Termenii și Condițiile de utilizare, politica de confidențialitate, utilizarea
                    datelor personale, cookie-urile sau orice alte aspecte legate de experiența dvs. pe site-ul nostru, vă
                    încurajăm să ne contactați.
                </p>
                <p>
                    <b>Cum ne puteți contacta:</b>
                </p>
                <ul>
                    <li>
                        Prin e-mail: Pentru întrebări generale, sugestii sau feedback, ne puteți trimite un e-mail la adresa viorel [dot] spinu
                        at gmail dot com.
                    </li>
                    <li>
                        Prin formularul de contact de pe site: Accesați secțiunea "Contact" de pe site-ul nostru pentru a trimite un
                        mesaj direct prin formularul disponibil online.
                    </li>
                </ul>
                <p>
                    Valorizăm feedback-ul pe care îl primim și ne străduim să îmbunătățim continuu serviciile pe care le oferim.
                    Toate comunicările sunt evaluate cu atenție, iar echipa noastră va răspunde solicitărilor în cel mai scurt
                    timp posibil.
                </p>
                <p>
                    Vă rugăm să rețineți că, în timp ce facem tot posibilul să asigurăm răspunsuri rapide și eficiente, timpul
                    de răspuns poate varia în funcție de volumul de solicitări primite și de complexitatea întrebărilor sau a
                    problemelor ridicate.
                </p>
                <p>
                    <b>Confidențialitatea comunicării:</b> Toate comunicările cu curs.viorelspinu.com sunt tratate cu un nivel înalt de confidențialitate. Informațiile
                    personale furnizate în cadrul comunicării vor fi procesate în conformitate cu politica noastră de
                    confidențialitate și legislația aplicabilă privind protecția datelor.
                </p>
            </div>
        </>
    )
}

export default TermsBody;