import React from "react";
import Header from "./Header.js"
import Footer from "./Footer.js"


const ConfidentialityModalBody = () => {
    return (
        <>
            <div className="container">
                <h1>Politica de Confidențialitate</h1>
                <br />
                <h3>Introducere</h3>
                <p>
                    Confidențialitatea vizitatorilor website-ului nostru este foarte importantă pentru noi și ne luăm
                    angajamentul să o protejăm. Această politică explică ce vom face cu informațiile dumneavoastră personale.
                    Acordarea consimțământului asupra utilizării cookie-urilor în concordanță cu termenii acestei politici
                    atunci când vizitați website-ul nostru pentru prima dată, ne permite să utilizăm cookie-uri de fiecare dată
                    când vizitați website-ul nostru.
                </p>
                <h3>Colectarea informațiilor personale</h3>
                <p>
                    Pot fi colectate, stocate și utilizate următoarele tipuri de informații personale:
                </p>
                <ul>
                    <li>informații despre computer, inclusiv adresa IP, locația geografică, tipul și versiunea browser-ului și despre sistemul de operare;</li>
                    <li>informații despre vizitele și utilizarea acestui website, inclusiv sursa de recomandare, durata vizitei, vizualizările paginii și căile de navigare pe website;</li>
                    <li>informații precum adresa dvs. de e-mail, pe care le introduceți atunci când vă înregistrați pe website-ul nostru;</li>
                    <li>informațiile pe care le introduceți atunci când creați un profil pe website-ul nostru – de exemplu, numele dvs., pozele de profil, sexul, data nașterii, starea civilă, interese și hobby-uri, detalii academice și detalii despre ocupație;</li>
                    <li>informații precum numele și adresa dvs. de e-mail, pe care le introduceți pentru a configura abonările la e-mailurile și/sau la buletinele noastre informative;</li>
                    <li>informații pe care le introduceți în timp ce utilizați serviciile de pe website-ul nostru;</li>
                    <li>informații care sunt generate în timp ce utilizați website-ul nostru, inclusiv despre când, cât de des și în ce circumstanțe îl utilizați;</li>
                    <li>informațiile pe care le postați pe website-ul nostru cu intenția de a le publica pe internet, care pot include numele dvs. de utilizator, imaginile de profil și conținutul postărilor;</li>
                    <li>informații conținute în orice comunicări ne trimiteți prin e-mail sau prin intermediul website-ului nostru, inclusiv conținutul comunicărilor și metadatele acestora;</li>
                    <li>orice alte informații personale pe care ni le trimiteți.</li>
                </ul>
                <p>
                    Înainte de a ne divulga informațiile personale ale unei alte persoane, trebuie să obțineți consimțământul
                    acelei persoanei atât pentru divulgarea, cât și pentru procesarea informațiilor personale în conformitate cu
                    această politică.
                </p>
                <h3>Utilizarea informațiilor dvs. personale</h3>
                <p>
                    Informațiile personale transmise prin intermediul website-ului nostru vor fi utilizate în scopurile
                    specificate de această politică sau în paginile respective ale website-ului. Putem folosi informațiile dvs.
                    personale pentru:
                </p>
                <ul>
                    <li>administrarea website-ului și a afacerii noastre;</li>
                    <li>personalizarea website-ului nostru pentru dvs.;</li>
                    <li>autorizarea utilizării serviciilor disponibile pe website-ul nostru;</li>
                    <li>trimiterea de comunicări comerciale în vederea informării;</li>
                    <li>trimiterea prin e-mail de notificări solicitate în mod expres;</li>
                    <li>trimiterea buletinului nostru informativ prin e-mail, dacă l-ați solicitat (ne puteți informa oricând dacă nu mai doriți această comunicare);</li>
                    <li>trimiterea de comunicări de marketing referitoare la afacerea noastră prin e-mail sau tehnologii similare (ne puteți informa în orice moment dacă nu mai doriți să primiți comunicări de marketing);</li>
                    <li>furnizarea către terți a informațiilor statistice despre utilizatorii noștri (acești terți nu vor putea identifica niciun utilizator cu ajutorul acestor informații);</li>
                    <li>abordarea solicitărilor și reclamațiilor făcute de dvs. sau despre dvs. referitoare la website-ul nostru;</li>
                    <li>păstrarea securității website-ului nostru și prevenirea fraudelor;</li>
                    <li>verificarea respectării termenilor și condițiilor care reglementează utilizarea website-ului nostru (inclusiv monitorizarea mesajelor private trimise prin intermediul serviciului nostru de mesagerie privată);</li>
                    <li>alte utilizări.</li>
                </ul>
                <p>
                    Dacă trimiteți informații personale în vederea publicării pe website-ul nostru, vom publica și vom folosi
                    aceste informații în concordanță cu întinderea acordului pe care ni-l furnizați.
                </p>
                <p>
                    Setările de confidențialitate pot fi utilizate pentru a limita publicarea informațiilor dvs. pe website-ul
                    nostru și pot fi modificate cu ajutorul comutatoarelor de confidențialitate de pe website.
                </p>
                <p>
                    Fără consimțământul dvs. expres, nu vom furniza informațiile dvs. personale către terți pentru marketingul
                    direct din partea acestor terți sau din partea oricărei alte terțe părți.
                </p>
                <h3>Divulgarea informațiilor personale</h3>
                <p>
                    Putem divulga informațiile dvs. personale oricăruia dintre angajații, ofițerii, asigurătorii, consilierii
                    profesioniști, agenții, furnizorii sau subcontractanții noștri după cum este necesar în mod rezonabil pentru
                    scopurile prevăzute în această politică.
                </p>
                <p>
                    Putem divulga informațiile dvs. personale:
                </p>
                <ul>
                    <li>în măsura în care ni se solicită acest lucru prin lege;</li>
                    <li>în legătură cu orice procedură legală potențială sau în curs;</li>
                    <li>oricărei persoane considerăm în mod rezonabil că s-ar putea adresa unei instanțe sau unei alte autorități
                        competente pentru divulgarea informațiilor personale în cazul în care, în opinia noastră rezonabilă, o
                        astfel de instanță sau autoritate ar putea să dispună divulgarea informațiilor personale.
                    </li>
                </ul>
                <p>
                    Cu excepția situațiilor prevăzute în această politică, nu vom furniza informațiile dvs. personale către
                    terți.
                </p>
                <h3>Transferuri internaționale de date</h3>
                <p>
                    Informațiile pe care le colectăm pot fi stocate, prelucrate și transferate între oricare dintre țările în
                    care funcționăm pentru a ne permite să utilizăm informațiile în conformitate cu această politică.
                </p>
                <p>
                    Informațiile colectate pot fi transferate între următoarele țări care nu au instituite legi de protecție a
                    datelor personale echivalente cu cele în vigoare în Spațiul Economic European: Statele Unite ale Americii.
                </p>
                <p>
                    Informațiile personale pe care le publicați pe website-ul nostru sau pe care le trimiteți spre publicare pe
                    website-ul nostru pot fi disponibile prin internet în întreaga lume. Nu putem împiedica utilizarea sau
                    utilizarea greșită a acestor informații de către alții.
                </p>
                <p>
                    Sunteți de acord în mod expres cu transferurile de informații personale descrise în această secțiune.
                </p>
                <h3>Păstrarea informațiilor personale</h3>
                <p>
                    Această Secțiune G stabilește politicile și procedurile noastre de păstrare a datelor, care sunt
                    concepute pentru a ajuta la asigurarea respectării obligațiilor noastre legale cu privire la păstrarea și
                    ștergerea informațiilor personale.
                </p>
                <p>
                    Informațiile personale pe care le prelucrăm cu orice scop sau scopuri nu vor fi păstrate mai mult decât este
                    necesar pentru acel scop sau scopuri.
                </p>
                <p>
                    În pofida celorlalte dispoziții din această secțiune, vom păstra documente (inclusiv documente
                    electronice) care conțin date cu caracter personal:
                </p>
                <ul>
                    <li>în măsura în care ni se solicită acest lucru prin lege;</li>
                    <li>dacă considerăm că documentele pot fi relevante pentru orice procedură legală în derulare sau viitoare;</li>
                </ul>
                <h3>Securitatea informațiilor dvs. personale</h3>
                <p>
                    Vom lua măsuri de precauție tehnice și organizaționale rezonabile pentru a preveni pierderea, utilizarea
                    necorespunzătoare sau modificarea informațiilor dvs. personale.
                </p>
                <p>
                    Vom stoca toate informațiile personale pe care le oferiți pe serverele noastre securizate.
                </p>
                <p>
                    Toate tranzacțiile financiare electronice încheiate prin intermediul website-ului nostru vor fi protejate de
                    tehnologia de criptare.
                </p>
                <p>
                    Ați luat la cunoștință faptul că transmiterea informațiilor pe internet este în mod obișnuit nesigură și nu
                    putem garanta securitatea datelor trimise pe internet.
                </p>
                <p>
                    Sunteți responsabil(ă) pentru păstrarea confidențialității parolei pe care o utilizați pentru accesarea
                    website-ului nostru; nu vă vom solicita niciodată parola (cu excepția momentului când vă conectați pe
                    website-ul nostru).
                </p>
                <h3>Modificări</h3>
                <p>
                    Ne rezervăm dreptul de a actualiza această politică din când în când publicând o versiune nouă pe website-ul
                    nostru. Trebuie să verificați ocazional această pagină pentru a vă asigura că înțelegeți orice modificare
                    adusă acestei politici. Vă putem anunța despre modificările aduse acestei politici prin e-mail.
                </p>
                <h3>Drepturile dvs.</h3>
                <p>
                    Ne puteți solicita să vă oferim orice informații personale deținem despre dvs.; furnizarea acestor
                    informații va fi supusă următorilor termeni:
                </p>
                <ul>
                    <li>furnizarea de dovezi suficiente cu privire la identitatea dvs.</li>
                </ul>
                <p>
                    Ne puteți solicita în orice moment să nu prelucrăm informațiile dvs. personale în scopuri de marketing.
                </p>
                <p>
                    În practică, de obicei fie vă exprimați acordul expres prealabil cu privire la utilizarea informațiilor dvs.
                    personale în scopuri de marketing, fie vă vom oferi posibilitatea de a renunța la utilizarea informațiilor
                    dvs. personale în scopuri de marketing.
                </p>
                <h3>Site-uri terțe</h3>
                <p>
                    Site-ul nostru include hyperlink-uri către și detalii despre site-uri terțe. Nu deținem niciun control și nu
                    de facem responsabili pentru politicile și practicile de confidențialitate ale terților.
                </p>
                <h3>Actualizarea informațiilor</h3>
                <p>
                    Vă rugăm să ne anunțați dacă informațiile personale pe care le deținem despre dvs. trebuie să fie corectate
                    sau actualizate.
                </p>
                <h3>Cookie-uri</h3>
                <p>
                    Website-ul nostru folosește cookie-uri. Cookie-ul este un fișier care conține un identificator (un șir de
                    litere și numere), care este trimis de un server web către un browser și care este stocat de acel browser.
                    Identificatorul este apoi trimis înapoi la server de fiecare dată când browser-ul solicită o pagină de la
                    acesta. Cookie-urile pot fi cookie-uri „persistente” sau cookie-uri „de sesiune”: cookie-ul persistent va fi
                    stocat de către browser și va rămâne valabil până la data stabilită de expirare, cu excepția cazului în care
                    acesta este șters înainte de data de expirare; pe de altă parte, cookie-ul de sesiune va expira la sfârșitul
                    sesiunii utilizatorului, la închiderea browser-ului. Cookie-urile nu conțin de obicei informații care
                    identifică personal un utilizator, dar informațiile personale pe care le stocăm despre dvs. pot fi legate de
                    informațiile stocate și obținute prin cookie-uri.
                </p>
                <p>
                    Numele cookie-urilor pe care le folosim pe website-ul nostru și scopurile pentru care sunt utilizate acestea
                    pot fi găsite mai jos:
                </p>
                <p>
                    Folosim Google Analytics și Adwords pe website-ul nostru pentru a recunoaște un computer atunci când un
                    utilizator vizitează
                    website-ul / urmărește utilizatorii cât timp navighează pe website / îmbunătățește capacitatea de utilizare
                    a website-ului / analizează utilizarea
                    website-ului / previne fraudele și îmbunătățește securitatea website-ului /
                    personalizează website-ul pentru fiecare utilizator ;
                </p>
                <p>
                    Majoritatea browser-elor vă permit să refuzați să acceptați cookie-uri – de exemplu:
                </p>
                <ul>
                    <li>în Internet Explorer (versiunea 10) puteți bloca cookie-urile utilizând suprascrierea setărilor de manipulare a cookie-urilor, disponibile printr-un clic pe „Instrumente”, „Opțiuni de Internet”, „Confidențialitate”, apoi „Avansat”;</li>
                    <li>în Firefox (versiunea 24) puteți bloca toate cookie-urile făcând clic pe „Instrumente”, „Opțiuni”, „Confidențialitate”, selectând „Utilizați setări personalizate pentru istoric” din meniul derulant și debifând „Acceptați cookie-uri de la website-uri”;</li>
                    <li>în Chrome (versiunea 29), puteți bloca toate cookie-urile accesând meniul „Personalizare și control”, făcând clic pe „Setări”, „Afișați setările avansate” și pe „Setări de conținut”, apoi selectând „Blocați site-urile să trimită orice date” la rubrica „Cookie-uri”.</li>
                </ul>
                <p>
                    Blocarea tuturor cookie-urilor va avea un impact negativ asupra utilizabilității multor website-uri. Dacă
                    blocați cookie-urile, nu veți putea utiliza toate funcțiile de pe website-ul nostru.
                </p>
                <p>
                    Puteți șterge cookie-urile deja stocate pe computer – de exemplu:
                </p>
                <ul>
                    <li>În Internet Explorer (versiunea 10), trebuie să ștergeți manual fișierele cookie (puteți găsi instrucțiuni despre cum puteți face acest lucru la http://support.microsoft.com/kb/278835 );</li>
                    <li>În Firefox (versiunea 24), puteți șterge cookie-urile făcând clic pe „Instrumente”, „Opțiuni” și „Confidențialitate”, apoi selectând „Utilizați setări personalizate pentru istoric”, făcând clic pe „Afișare cookie-uri”, apoi pe „Ștergeți toate Cookie-urile“;</li>
                    <li>în Chrome (versiunea 29), puteți șterge toate cookie-urile accesând meniul „Personalizare și control”, făcând clic pe „Setări”, „Afișați setările avansate” și pe „Ștergeți datele de navigare”, apoi selectați „Ștergeți cookie-urile și alte date despre website și plugin-uri” și faceți clic pe „Ștergeți datele de navigare”.</li>
                </ul>
                <p>
                    Ștergerea cookie-urilor va avea un impact negativ asupra utilizabilității multor website-uri.
                </p>
            </div>
        </>
    )
}

export default ConfidentialityModalBody;