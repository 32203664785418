const HowItWorks = ({ showRegisterDialogParent }) => {
    const handleLinkClick = (event) => {
        event.preventDefault();
        showRegisterDialogParent();
    };
    return (<>
        <a name="cum_functioneaza"></a>
        <section className="bg-2">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12" style={{ padding: "20px", paddingTop: "50px" }}>
                        <div className='row label_text' style={{
                            color: "#333", marginTop: "20px"
                        }}>
                            <div className="col" >
                                <h2>Cum funcționeazǎ?</h2>
                                <ul>
                                    <li>Te <a className="a_link" href="#" onClick={handleLinkClick}>înscrii la curs.</a></li>
                                    <li>O datǎ pe sǎptǎmânǎ îți trimit o lecție despre inteligența artificială prin e-mail. </li>
                                    <li>Adresa ta de e-mail este mereu în siguranțǎ. </li>
                                    <li>Poți renunța oricând. </li>
                                    <li>Totul este gratuit. </li>
                                </ul>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <h2>Cine sunt eu?</h2>
                            <div
                                className="col-12 col-md-2 d-flex align-items-center justify-content-md-end justify-content-start">
                                <a href="https://viorelspinu.com" target="_blank">
                                    <img src="assets/viorelspinu.jpg" alt="Imagine descriere" style={{ height: "200px" }} />
                                </a>
                            </div>
                            <div className='col-12 col-md-10 label_text prezentare_viorel'
                                style={{
                                    textAlign: "left", color: "#333"
                                }}>
                                <div style={{ textAlign: "justify" }}>
                                    Mǎ numesc Viorel Spînu. Activez în zona tehnologiei <a className="a_link"
                                        href="https://www.google.com/search?q=viorel+spinu+tehnologie"
                                        target="_blank">de 25 de ani</a>. Sunt pasionat de <a className="a_link"
                                            href="https://www.viorelspinu.com/search/label/ai" target="_blank">inteligența
                                        artificială</a>. Construiesc <a className="a_link"
                                            href="https://www.slideshare.net/search?searchfrom=header&q=viorel+spinu"
                                            target="_blank">sisteme software</a>. Am <a className="a_link"
                                                href="https://techcrunch.com/2009/09/22/twitter-iphone-balloon-nears-nerdgasm-event-horizon/"
                                                target="_blank">umflat baloane</a> si am <a className="a_link"
                                                    href="https://www.google.com/search?q=beer+keyboard+robofun&sca_esv=575486894&sxsrf=AM9HkKlt0FHBSqGGSxUlau3Hn-tY4u0EHw:1697917566545&ei=fio0ZZj0IOfvsAfPsYy4DA&ved=0ahUKEwiYq6Xr84eCAxXnN-wKHc8YA8cQ4dUDCBA&uact=5&oq=beer+keyboard+robofun"
                                                    target="_blank">facut tastaturi din cutii de bere</a>. Am construit
                                    <a href='https://www.viorelspinu.com/search/label/robotics' className="a_link"
                                        target="_blank"> roboți</a>.
                                    Îmi place <a href="https://www.viorelspinu.com/search/label/public%20speaker" className="a_link"
                                        target="_blank">să vorbesc despre pasiunile mele.</a>

                                    Vreau sǎ te ajut sǎ ții pasul cu inteligența artificialǎ. În acest scop am creat
                                    cursul gratuit de AI. Este suficient sǎ îmi laşi adresa ta de e-mail si o datǎ pe
                                    sǎptǎmânǎ îți voi trimite
                                    o lecție nouǎ. Mai multe despre mine găsești pe <a className="a_link" href="https://viorelspinu.com"
                                        target="_blank">blog-ul meu.</a>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section></>)
}

export default HowItWorks;
