import React, { useEffect } from "react";
import "./IndexPage.css";
import { useState } from 'react';
import Header from "../components/Header.js"
import Footer from "../components/Footer.js"
import ReasonsToJoin from "../components/ReasonsToJoin.js"
import TopIntro from "../components/TopIntro.js"
import HowItWorks from "../components/HowItWorks.js"
import ConferenceSpeaker from "../components/ConferenceSpeaker.js";
import QAndA from "../components/QAndA.js";
import { Modal } from 'react-responsive-modal';
import RegisterModalBody from "../components/RegisterModalBody.js"
import ContactModalBody from "../components/ContactModalBody.js"
import ConfidentialityModalBody from "../components/ConfidentialityModalBody.js"
import MessageModalBody from "../components/MessageModalBody.js"
import HttpActionModalBody from "../components/HttpActionModalBody.js"
import TermsBody from "../components/TermsBody.js"
import { notify } from "../api.js";
import 'react-responsive-modal/styles.css';


const IndexPage = ({ page }) => {
    const [registerModalOpen, setRegisterModalOpen] = useState(false);
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [httpActionlModalOpen, setHttpActionModalOpen] = useState(false);

    const showRegisterDialog = () => setRegisterModalOpen(true);
    const closeRegisterDialog = () => setRegisterModalOpen(false);
    const showContactDialog = () => setContactModalOpen(true);
    const closeContactDialog = () => setContactModalOpen(false);
    const showMessageDialog = () => setMessageModalOpen(true);
    const closeMessageDialog = () => setMessageModalOpen(false);
    const showHttpActionDialog = () => setHttpActionModalOpen(true);
    const closeHttpActionDialog = () => setHttpActionModalOpen(false);

    useEffect(() => {
        if (page == "message") {
            setMessageModalOpen(true);
        }
        if (page == "contact") {
            setContactModalOpen(true);
        }
        if (page == "confirm_email") {
            setHttpActionModalOpen(true);
        }
        if (page == "unsubscribe") {
            setHttpActionModalOpen(true);
            notify(window.location);
        }
    }, []);



    return (
        <>
            <Modal animationDuration={0}
                open={registerModalOpen} onClose={closeRegisterDialog} center showCloseIcon={false} classNames={{ modal: 'modalClass' }}>
                <RegisterModalBody hideModal={closeRegisterDialog} />
            </Modal>
            <Modal animationDuration={0}
                open={contactModalOpen} onClose={closeContactDialog} center showCloseIcon={false} classNames={{ modal: 'modalClass' }}>
                <ContactModalBody hideModal={closeContactDialog} />
            </Modal>
            <Modal animationDuration={0}
                open={messageModalOpen} onClose={closeMessageDialog} center showCloseIcon={false} classNames={{ modal: 'modalClass' }}>
                <MessageModalBody hideModal={closeMessageDialog} />
            </Modal>
            <Modal animationDuration={0}
                open={httpActionlModalOpen} onClose={closeHttpActionDialog} center showCloseIcon={false} classNames={{ modal: 'modalClass' }}>
                <HttpActionModalBody hideModal={closeHttpActionDialog} page={page} />
            </Modal>


            <Header showAskAnythingDialog={showContactDialog}></Header>
            {(page == "index" || page == "message" || page == "contact" || page == "confirm_email" || page == "unsubscribe") && (
                <>
                    <TopIntro showRegisterDialogParent={showRegisterDialog}></TopIntro>
                    <HowItWorks showRegisterDialogParent={showRegisterDialog}></HowItWorks>
                    <ConferenceSpeaker></ConferenceSpeaker>
                    <ReasonsToJoin></ReasonsToJoin>
                    <QAndA showAskAnythingDialog={showContactDialog}></QAndA>
                </>
            )}
            {(page == "terms") && (
                <>
                    <TermsBody></TermsBody>
                </>
            )}
            {(page == "confidentiality") && (
                <>
                    <ConfidentialityModalBody></ConfidentialityModalBody>
                </>
            )}


            <Footer showAskAnythingDialog={showContactDialog}></Footer>
        </>
    )
}

export default IndexPage;