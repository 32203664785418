import React from "react";
import './App.css';
import IndexPage from "./pages/IndexPage.js";
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<IndexPage page={"index"}></IndexPage>} />
          <Route path="/terms" element={<IndexPage page={"terms"}></IndexPage>} />
          <Route path="/confidentiality" element={<IndexPage page={"confidentiality"}></IndexPage>} />
          <Route path="/message" element={<IndexPage page={"message"}></IndexPage>} />
          <Route path="/contact" element={<IndexPage page={"contact"}></IndexPage>} />
          <Route path="/confirm-email" element={<IndexPage page={"confirm_email"}></IndexPage>} />
          <Route path="/unsubscribe" element={<IndexPage page={"unsubscribe"}></IndexPage>} />
        </Routes>
      </Router>



    </>
  );
}

export default App;
