import axios from 'axios';
import { BACKEND_URL, MAKE_ERROR_NOTIFICATION_URL } from './constants.js';


const instance = axios.create({
    baseURL: BACKEND_URL,
    withCredentials: true,
});


function sendErrorLog(error, url) {
    const errorData = {
        url: url,
        message: error.message,
        name: error.name,
        config: error.config,
        code: error.code,
    };

    const errorLogUrl = `${MAKE_ERROR_NOTIFICATION_URL}?error=${encodeURIComponent(JSON.stringify(errorData))}`;
    axios.get(errorLogUrl).catch(err => {
        console.error('Error logging failed:', err);
    });
}

function notify(what) {
    try {
        const notifyUrl = `${MAKE_ERROR_NOTIFICATION_URL}?error=${encodeURIComponent(JSON.stringify(what))}`;
        axios.get(notifyUrl);
    } catch {
        console.log("notification error");
    }
}


function handleApiError(promise, url) {
    return promise.catch(error => {
        console.error('API call error:', error);
        sendErrorLog(error, url);
    });
}

function pingServer() {
    const apiUrl = `${BACKEND_URL}/ping`;
    return handleApiError(instance.get(apiUrl), apiUrl);
}

function register(name, email) {
    const apiUrl = `${BACKEND_URL}/emails`;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);

    const requestOptions = {
        method: 'POST',
        data: formData,
    };

    return instance(apiUrl, requestOptions)
}

function contact(email, question) {
    const apiUrl = `${BACKEND_URL}/contact`;

    const formData = new FormData();
    formData.append('email', email);
    formData.append('question', question);

    const requestOptions = {
        method: 'POST',
        data: formData,
    };

    return handleApiError(instance(apiUrl, requestOptions), apiUrl);
}

function confirm_email(id) {
    const apiUrl = `${BACKEND_URL}/confirm_email?id=${id}`;
    const requestOptions = {
        method: 'GET',
    };

    return instance(apiUrl, requestOptions);
}


function unsubscribe(id) {
    const apiUrl = `${BACKEND_URL}/unsubscribe?id=${id}`;
    const requestOptions = {
        method: 'GET',
    };

    return instance(apiUrl, requestOptions);
}


export { pingServer, register, contact, confirm_email, unsubscribe, notify }