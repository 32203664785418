const QAndA = ({ showAskAnythingDialog }) => {

    const handleLinkClick = (event) => {
        event.preventDefault();
        showAskAnythingDialog();
    };

    return (<section className="bg-2 pb-5">
        <div className="container" style={{ textAlign: "justify" }}>
            <div className="row justify-content-center">
                <div className="col-12" style={{ padding: "20px", paddingTop: "50px" }}>
                    <div className='row'>
                        <div className='row label_text' style={{ color: "#333", marginTop: "30px" }}>
                            <div className="col">
                                <div className="mb-2">
                                    <b>Este un curs pentru programatori?</b>
                                </div>
                                <div>
                                    Nu. Acest curs nu este destinat programatorilor. Cursul este adresat persoanelor
                                    fără experiență în programare sau în domeniul tehnologiei informației.
                                    Conținutul este conceput pentru a fi accesibil și ușor de înțeles, fără a
                                    presupune cunoștințe prealabile în scrierea de software sau în alte aspecte
                                    tehnice specifice domeniului IT.
                                </div>
                            </div>
                        </div>
                        <div className='row label_text' style={{ color: "#333", marginTop: "30px" }}>
                            <div className="col">
                                <div className="mb-2">
                                    <b>Cum faci bani? Vinzi adresele de e-mail?</b>
                                </div>
                                <div>
                                    Nu vând adresele de e-mail. Datele tale sunt in siguranțǎ. Nu intenționez sǎ fac
                                    bani în mod
                                    direct din acest curs. Îmi doresc sa ajut şi sǎ cunosc oameni.
                                </div>
                            </div>
                        </div>
                        <div className='row label_text' style={{ color: "#333", marginTop: "30px" }}>
                            <div className="col">
                                <div className="mb-2">
                                    <b>Şi atunci, de ce faci asta?</b>
                                </div>
                                <div>
                                    Pentru că vreau să ajut. Îmi place să împărtășesc ce știu. Am descoperit cǎ
                                    atunci când explici un lucru de mai multe ori, ajungi sa îl înțelegi pânǎ şi tu.
                                </div>
                            </div>
                        </div>
                        <div className='row label_text' style={{ color: "#333", marginTop: "30px" }}>
                            <div className="col">
                                <div className="mb-2">
                                    <b>Mai am o întrebare!</b>
                                </div>
                                <div>
                                    Sigur, <a className="a_link" href="#" onClick={handleLinkClick}>fǎ click aici</a> si întreabǎ-mǎ
                                    orice.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default QAndA;