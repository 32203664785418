const ConferenceSpeaker = () => {
    return (<section className="bg-2">
        <div className="movie_container">
            <div className="row justify-content-center">
                <div style={{ marginTop: "15px", paddingBottom: "50px" }}>
                    <div style={{ marginTop: "15px" }} className="me-image-container">
                        <a href="https://stirileprotv.ro/ilikeit/chatgpt/prima-lege-privind-inteligenta-artificiala-viorel-spinu-specialist-in-a-i-are-reglementari-bune-dar-opresc-inovatia.html"
                            target="_blank">
                            <img src="assets/protv1.jpg?1" className="me-image" />
                        </a>
                        <a href="https://www.viorelspinu.com/2023/11/ai-unexpected-scenarios-speaker-at.html"
                            target="_blank">
                            <img src="assets/marketing.jpg?1" className="me-image" />
                        </a>
                        <a href="https://www.viorelspinu.com/2023/11/aispeaker-at-absl-11th-annual-conference.html"
                            target="_blank">
                            <img src="assets/absl.jpg?1" className="me-image" />
                        </a>
                        <a href="https://www.viorelspinu.com/2023/01/ive-been-on-protv-evening-news.html"
                            target="_blank">
                            <img src="assets/protv4.jpg?1" className="me-image" />
                        </a>
                        <a href="https://www.viorelspinu.com/2023/05/speaker-at-cegeka-ai-data-alchemy.html"
                            target="_blank">
                            <img src="assets/cegeka1.jpg?1" className="me-image" />
                        </a>
                        <a href="https://stirileprotv.ro/stiri/ilikeit/ilikeit-sun-fricile-greilor-din-silicon-valley-privind-inteligenta-artificiala-motivate-explicatiile-specialistilor.html"
                            target="_blank">
                            <img src="assets/protv2.jpg?1" className="me-image" />
                        </a>
                        <a href="https://www.viorelspinu.com/2023/04/speaker-at-estee-lauder-on-artificial.html"
                            target="_blank">
                            <img src="assets/estee.jpg?1" className="me-image" />
                        </a>
                        <a href="https://www.viorelspinu.com/2023/03/ive-been-live-on-tv.html" target="_blank">
                            <img src="assets/protv3.jpg?1" className="me-image" />
                        </a>
                        <a href="https://www.viorelspinu.com/2023/03/speaker-dwftalks.html" target="_blank">
                            <img src="assets/dwf.jpg?1" className="me-image" />
                        </a>
                        <a href="https://www.viorelspinu.com/2023/11/speaker-at-faculty-of-management.html"
                            target="_blank">
                            <img src="assets/management.jpg?1" className="me-image" />
                        </a>

                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default ConferenceSpeaker;