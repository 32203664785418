import React, { useState, useRef, useEffect } from 'react';

const ProgressBar = ({ isProgressStarted }) => {
    const [progress, setProgress] = useState(0);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (isProgressStarted) {
            startProgress();
        } else {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [isProgressStarted]);

    const startProgress = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        setProgress(0);

        intervalRef.current = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 99.9) {
                    clearInterval(intervalRef.current);
                    return 99.9;
                }
                const increment = 3 * (prevProgress < 30 ? 1 : 0.1 * (100 - prevProgress));
                return prevProgress + increment;
            });
        }, 400);
    };

    return (
        (isProgressStarted) && (
            <div className="progress mb-4 mt-4" style={{ width: '100%', height: '10px' }}>
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${progress}%`, backgroundColor: 'blue' }}
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>)
    );
};

export default ProgressBar;
