import React, { useState, useEffect } from 'react';
import "./ContactModalBody.css";
import ProgressBar from "./ProgressBar";
import { contact } from '../api';

const messageMap = {
    SUGESTIONS: "În lecția urmǎtoarea aș vrea sǎ citesc despre...",
    FEEDBACK: "Mi-a plăcut: \n\nNu mi-a plăcut: \n\nCred că ar trebui să: \n\n",
    SOMETHING_IS_WRONG: "Cred cǎ ar trebui sǎ mai arunci un ochi peste... \n\n"

};


const ContactModalBody = ({ hideModal }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoMessageClass, setInfoMessageClass] = useState('');
    const [isProgressStarted, setIsProgressStarted] = useState(false);

    useEffect(() => {
        const hash = window.location.hash;
        const queryString = hash.substring(hash.indexOf('?') + 1);
        const urlParams = new URLSearchParams(queryString);
        const messageCode = urlParams.get('code');
        const messageData = messageMap[messageCode];
        if (!messageData) {
            return;
        }
        const message = messageData;
        setMessage(message);
    }, []);

    const handleSubmit = async () => {

        setInfoMessageClass('light');
        setInfoMessage('Stocǎm datele. Dureazǎ câteva zeci de secunde.');
        setIsProgressStarted(true);
        await contact(email, message);
        setIsProgressStarted(false);
        setSubmitted(true);
        setInfoMessageClass('success');
        if (email) {
            setInfoMessage('Am primit. Revin cu un rǎspuns prin email.');
        } else {
            setInfoMessage('Am primit. Mulțumesc pentru ajutor.');
        }
    };

    const handleCancel = () => {
        setIsProgressStarted(false);
        hideModal();
    };

    return (
        <div className='mt-2 p-0 p-md-3'>
            <div style={{ width: '100%', textAlign: 'center' }}>
                {(infoMessage) && (<div id="infoModalMessage" style={{ fontSize: '120%' }} className={`mt-3 alert alert-${infoMessageClass}`}>{infoMessage}</div>)}
            </div>
            <ProgressBar isProgressStarted={isProgressStarted}></ProgressBar>

            <div>
                <div className='mb-4'>
                    <div className='m-0 m-md-3'>
                        <textarea
                            className="form-control"
                            id="message"
                            placeholder="Cum te pot ajuta?"
                            rows="7"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            disabled={submitted || isProgressStarted}
                        ></textarea>
                    </div>
                </div>

                <div className='mb-4'>
                    <div className='m-0 m-md-3'>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Adresa ta de email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={submitted || isProgressStarted}
                        />
                    </div>
                </div>

            </div>

            {(!submitted) && (
                <div className="d-flex justify-content-end">
                    <button disabled={submitted || isProgressStarted} className="btn btn-secondary m-2" onClick={handleCancel}>Renunțǎ</button>
                    <button disabled={submitted || isProgressStarted} className="btn btn-primary m-2" onClick={handleSubmit}>Trimite</button>
                </div>
            )}

            {(submitted) && (
                <div className="d-flex justify-content-end">
                    <button disabled={!submitted || isProgressStarted} className="btn btn-primary m-2" onClick={handleCancel}>Închide</button>
                </div>
            )}
        </div>
    );
};

export default ContactModalBody;
