import React, { useState, useEffect } from 'react';
import "./HttpActionModalBody.css";
import ProgressBar from "./ProgressBar";
import { confirm_email, unsubscribe, notify } from '../api';


const HttpActionModalBody = ({ hideModal, page }) => {
    const [submitted, setSubmitted] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoMessageClass, setInfoMessageClass] = useState('');
    const [isProgressStarted, setIsProgressStarted] = useState(false);

    useEffect(() => {
        let pleaseWaitMessage = null;
        let okMessage = null;
        let notOkMessage = null;
        if (page == "confirm_email") {
            pleaseWaitMessage = "Verificare in progress, te rog așteaptǎ.";
            okMessage = "Emailul tǎu este confirmat. Vei primi prima lecție în cel mult o sǎptǎmânǎ.";
            notOkMessage = "Ceva nu a funcționat corect. Lasǎ-mi te rog un mesaj folosind butonul din dreapta sus.";
        }
        if (page == "unsubscribe") {
            pleaseWaitMessage = "Stocare modificǎri, te rog așteaptǎ.";
            okMessage = "Confirm dezabonarea. Nu vei mai primi emailuri.";
            notOkMessage = "Ceva nu a funcționat corect. Îmi pare rǎu. Lasǎ-mi te rog un mesaj folosind butonul din dreapta sus, sau dǎ un reply la oricare dintre email-urile primite de la mine şi voi rezolva manual.";
        }
        const handleHttpAction = async () => {
            const hash = window.location.hash;
            const queryString = hash.substring(hash.indexOf('?') + 1);
            const urlParams = new URLSearchParams(queryString);
            const id = urlParams.get('code');
            if (!id) {
                return;
            }
            setInfoMessage(pleaseWaitMessage)
            setInfoMessageClass('light')
            setIsProgressStarted(true);
            let response = null;
            try {
                if (page == "confirm_email") {
                    response = await confirm_email(id);
                }
                if (page == "unsubscribe") {
                    response = await unsubscribe(id);
                }
            } catch {
                notify(page + "_ERROR: " + id + " " + window.location);
                setInfoMessage(notOkMessage);
                setInfoMessageClass('danger');
                return;
            } finally {
                setIsProgressStarted(false);
                setSubmitted(true);
            }
            if (response.status == 200) {
                setInfoMessage(okMessage)
                setInfoMessageClass('success')
            }

        };

        handleHttpAction();
    }, []);

    const handleCancel = () => {
        setIsProgressStarted(false);
        hideModal();
    };

    return (
        <div className='mt-2 p-0 p-md-3'>
            <div style={{ width: '100%', textAlign: 'center' }}>
                {(infoMessage) && (<div id="infoModalMessage" style={{ fontSize: '120%' }} className={`mt-3 alert alert-${infoMessageClass}`}>{infoMessage}</div>)}
            </div>
            <ProgressBar isProgressStarted={isProgressStarted}></ProgressBar>

            {(submitted) && (
                <div className="d-flex justify-content-end">
                    <button disabled={!submitted || isProgressStarted} className="btn btn-primary m-2" onClick={handleCancel}>Închide</button>
                </div>
            )}
        </div>
    );
};

export default HttpActionModalBody;
