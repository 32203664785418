import React, { useState } from 'react';
import "./RegisterModalBody.css";
import ProgressBar from "./ProgressBar"
import { register, notify } from '../api';

const RegisterModalBody = ({ hideModal }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [termsChecked, setTermsChecked] = useState(false);
    const [gdprChecked, setGdprChecked] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoMessageClass, setInfoMessageClass] = useState('');
    const [isProgressStarted, setIsProgressStarted] = useState(false);

    const handleSubmit = async () => {
        try {
            if (!termsChecked || !gdprChecked) {
                setInfoMessageClass('warning');
                setInfoMessage('Pentru a continua este necesar să acceptați termenii și politica de confidențialitate.');
                return;
            }

            setInfoMessageClass('light');
            setInfoMessage('Stocǎm datele. Dureazǎ câteva zeci de secunde.');
            setIsProgressStarted(true);
            await register(name, email);
            setIsProgressStarted(false);
            setSubmitted(true);
            setInfoMessageClass('success');
            setInfoMessage('Am trimis un email de confirmare. Te rog sǎ dai click pe link-ul din email ca sǎ confirmi adresa de email');
        } catch {
            setIsProgressStarted(false);
            setSubmitted(true);
            setInfoMessageClass('danger');
            setInfoMessage('Ceva nu a funcționat corect. Lasǎ-mi te rog un mesaj folosind butonul din dreapta sus.');
            notify("REGISTER_ERROR: " + name + " " + email + " " + window.location);
        } finally {

        }

    };

    const handleCancel = () => {
        setIsProgressStarted(false);
        hideModal();
    };


    return (
        <div className='mt-4'>
            <div style={{ width: '100%', textAlign: 'center' }}>
                {(infoMessage) && (<div id="infoModalMessage" style={{ fontSize: '120%' }} className={`mt-3 alert alert-${infoMessageClass}`}>{infoMessage}</div>)}
            </div>
            <ProgressBar isProgressStarted={isProgressStarted}></ProgressBar>


            <div>
                <div className='mb-4'>
                    <div className='ml-0 mr-0 mb-2 m-md-3'>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Nume şi prenume"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            disabled={submitted || isProgressStarted}
                        />
                    </div>

                    <div className='ml-0 mr-0 m-md-3'>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="adresa de email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={submitted || isProgressStarted}
                        />
                    </div>
                </div>

                <div className="mb-3 form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="termsAndConditions"
                        checked={termsChecked}
                        onChange={(e) => setTermsChecked(e.target.checked)}
                        disabled={submitted || isProgressStarted}
                    />
                    <label className="form-check-label" htmlFor="termsAndConditions">
                        Sunt de acord cu <a target="_blank" href="/#/terms">termenii și condițiile</a>
                    </label>
                </div>

                <div className="mb-3 form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="gdprPolicy"
                        checked={gdprChecked}
                        onChange={(e) => setGdprChecked(e.target.checked)}
                        disabled={submitted || isProgressStarted}
                    />
                    <label className="form-check-label" htmlFor="gdprPolicy">
                        Am citit și sunt de acord cu <a target="_blank" href="/#/confidentiality">politica de confidențialitate</a>
                    </label>
                </div>
            </div>

            {(!submitted) && (<div className="d-flex justify-content-end">
                <button disabled={submitted || isProgressStarted} className="btn btn-secondary m-2" onClick={handleCancel}>Renunțǎ</button>
                <button disabled={submitted || isProgressStarted} className="btn btn-primary m-2" onClick={handleSubmit}>Trimite</button>
            </div>)}

            {(submitted) && (<div className="d-flex justify-content-end">
                <button disabled={!submitted || isProgressStarted} className="btn btn-primary m-2" onClick={handleCancel}>Închide</button>
            </div>)}


        </div>
    );
}

export default RegisterModalBody;
